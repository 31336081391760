.location-field-wrapper .search-bar-wrapper,
.location-field-wrapper .search-bar-wrapper input {
  border: none !important;
  background: none !important;
  margin: 0 !important;
}
.location-field-wrapper .search-bar-wrapper {
  height: 24px !important;
  min-height: 24px !important;
  padding: 0 !important;
}

.location-field-wrapper .search-bar-wrapper input {
  color: #f8fafc !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 2px 0;
}

.location-field-wrapper .search-result-container {
  /* outline: 1px solid rgba(255, 255, 255, 0.1) !important; */
  min-width: 100% !important;
  border-width: 1px !important;
  border-color: rgba(130, 240, 255, 0.5);
  position: absolute !important;
  top: 92.3% !important;
  width: 100% !important;
  transform: translate(0) !important;
}
.location-field-wrapper .search-result-container:empty {
  border: none !important;
}

.location-field-wrapper .search-bar-wrapper input::placeholder {
  background: none !important;
  opacity: 0.5;
}

.location-field-wrapper .search-bar-wrapper > span {
  display: none;
}
.location-field-wrapper .search-bar-wrapper > span + div {
  /* background: none !important; */
  /* border: none !important; */
  /* padding: 0 !important; */
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
/* .location-field-wrapper .search-bar-wrapper > span + div button {
  display: none;
} */
