button.tile-select {
  @apply flex cursor-pointer items-center justify-between rounded-md border bg-input-background-unfilled p-4 text-base font-medium leading-[22px] outline-none;
  @apply border-input-stroke-unfilled text-content-base-default;
  @apply hover:border-input-stroke-unfilled hover:text-content-accent-default;
  @apply ring-button-stroke-primary-focus ring-offset-2 ring-offset-background-base-surface-1 focus:ring-2;
  @apply disabled:border-input-stroke-disabled disabled:bg-input-background-disabled disabled:text-button-content-secondary-disabled;
}

.tile-select.selected {
  @apply border-input-stroke-selected bg-input-background-filled fill-content-accent-default text-content-accent-default;
}

/* Blue variant styles */

button.tile-select.blue {
  @apply bg-background-base-surface-0;
}

.tile-select.selected.blue {
  @apply border-[#6C9DFF] fill-[#6C9DFF] text-[#6C9DFF];
}

button.blue:focus {
  @apply ring-[#6C9DFF] ring-offset-2 ring-offset-background-base-surface-1 focus:ring-2;
}

button.blue:hover {
  @apply border-[#6C9DFF] text-[#6C9DFF];
}
