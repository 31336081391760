.TimePicker {
  @apply flex min-h-[48px] items-center justify-between gap-2 rounded border px-4;
  @apply border-input-stroke-filled bg-input-background-filled fill-input-content-filled text-input-content-filled;
  @apply hover:border-input-stroke-hover;
  @apply focus-within:border-input-stroke-focus;
}

.TimePicker.disabled {
  @apply border-input-stroke-disabled bg-input-background-disabled fill-input-content-disabled text-input-content-disabled;
}

.TimePicker.readonly {
  @apply border-input-stroke-readonly bg-input-background-readonly fill-input-content-readonly text-input-content-readonly;
}

.TimePicker.error {
  @apply border-input-stroke-error bg-input-background-error fill-input-content-error text-input-content-error;
}

.TimePartContainer {
  @apply grid grid-cols-[2ch_fit-content(1ch)_2ch_4ch_fit-content(2ch)] gap-1;
}

.TimePartContainer .TimeSeparator {
  @apply text-input-content-unfilled;
}

.TimePartContainer .TimePartInput {
  @apply bg-transparent;
  @apply focus:outline-none;
  @apply invalid:text-input-content-error;
}
