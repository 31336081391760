[type='radio'] {
  display: none;
}

#slider {
  height: 400px;
  position: relative;
  perspective: 1000px;
  transform-style: preserve-3d;
}

#slider label {
  margin: auto;
  width: 50%;
  height: 100%;
  border-radius: 40px;
  position: absolute;
  left: 0;
  right: 0;
  cursor: pointer;
  transition: transform 0.4s ease;
}

#slider label {
  width: 684px;
}
#slider {
  display: flex;
  justify-content: center;
  align-items: center;
}

#s1:checked ~ #slide4,
#s2:checked ~ #slide5,
#s3:checked ~ #slide1,
#s4:checked ~ #slide2,
#s5:checked ~ #slide3 {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
  transform: translate3d(-30%, 0, 0);
}

#s1:checked ~ #slide5,
#s2:checked ~ #slide1,
#s3:checked ~ #slide2,
#s4:checked ~ #slide3,
#s5:checked ~ #slide4 {
  box-shadow:
    0 6px 10px 0 rgba(0, 0, 0, 0.3),
    0 2px 2px 0 rgba(0, 0, 0, 0.2);
  transform: translate3d(-15%, 0, 0);
}

#s1:checked ~ #slide1,
#s2:checked ~ #slide2,
#s3:checked ~ #slide3,
#s4:checked ~ #slide4,
#s5:checked ~ #slide5 {
  box-shadow:
    0 13px 25px 0 rgba(0, 0, 0, 0.3),
    0 11px 7px 0 rgba(0, 0, 0, 0.19);
  transform: translate3d(0, 0, 0);
}

#s1:checked ~ #slide2,
#s2:checked ~ #slide3,
#s3:checked ~ #slide4,
#s4:checked ~ #slide5,
#s5:checked ~ #slide1 {
  box-shadow:
    0 6px 10px 0 rgba(0, 0, 0, 0.3),
    0 2px 2px 0 rgba(0, 0, 0, 0.2);
  transform: translate3d(15%, 0, 0);
}

#s1:checked ~ #slide3,
#s2:checked ~ #slide4,
#s3:checked ~ #slide5,
#s4:checked ~ #slide1,
#s5:checked ~ #slide2 {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
  transform: translate3d(30%, 0, 0);
}

.carousel-container {
  position: relative;
  width: auto;
  overflow: hidden;
  min-height: 650px;
}

.slick-slide {
  display: flex;
  justify-content: center;
}

.slide {
  position: relative;
  transition: all 0.3s ease-out;
}

.active-slide {
  z-index: 3;
  width: 684px;
  height: 400px;
  border-radius: 40px;
  opacity: 1;
  transform: scale(1);
}

.active-slide:hover .overlay-active {
  background-color: rgba(30, 41, 59, 0.1);
}
.overlay-active {
  z-index: 5;
  background-color: rgba(30, 41, 59, 0.5);
  transition: background-color 0.3s ease-in-out;
}
.upcoming-slide-left,
.upcoming-slide-right {
  z-index: 2;
  width: 524px;
  height: 320px;
  border-radius: 40px;
  background: #334155;
  transform: scale(0.9);
  margin: 0 -25%;
}

.upcoming-slide-left {
  transform: scale(0.9) translateX(110%) translateY(10%);
}

.upcoming-slide-right {
  transform: scale(0.9) translateX(-40%) translateY(10%);
}

.far-slide-left,
.far-slide-right {
  z-index: 1;
  width: 364px;
  height: 240px;
  border-radius: 40px;
  background-color: #475569;
  opacity: 0.5;
  transform: scale(0.8);
  margin: 0 -50%;
}

.far-slide-left {
  transform: scale(0.8) translateX(260%) translateY(35%);
}

.far-slide-right {
  transform: scale(0.8) translateX(-120%) translateY(35%);
}

.slide-content {
  padding: 32px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.active-slide .slide-content {
  opacity: 1;
}

.gradient-bg {
  background: linear-gradient(to right, #82f0ff, #9796f3, #ff9a51);
  border-radius: 40px;
}

.carousel-nav-wrapper {
  position: absolute;
  margin-top: 24px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-nav {
  display: flex;
  align-items: center;
  gap: 20px;
}

.carousel-dots {
  display: flex;
  align-items: center;
  gap: 10px;
}

.carousel-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #2b2b3f;
  cursor: pointer;
  transition: background-color 0.3s;
}

.carousel-dot.active {
  background-color: #9796f3;
  width: 16px;
  height: 16px;
}

.carousel-arrow {
  width: 24px;
  height: 24px;
  cursor: pointer;
  fill: #94a3b8;
}

.carousel-arrow:hover {
  fill: #5a67d8;
}

@media screen and (min-width: 1280px) {
  .upcoming-slide-right {
    margin-right: 18% !important;
    height: 320px !important;
    width: 524px !important;
  }
  .far-slide-right {
    margin-right: 22% !important;
    height: 240px !important;
  }
  .upcoming-slide-left {
    margin-left: 18% !important;
    height: 320px !important;
    width: 524px !important;
  }
  .far-slide-left {
    margin-left: 22% !important;
    height: 240px !important;
  }
}

@media screen and (min-width: 1600px) {
  .upcoming-slide-right {
    margin-right: 25% !important;
    height: 320px !important;
    width: 524px !important;
  }
  .far-slide-right {
    margin-right: 24% !important;
    height: 240px !important;
  }
  .upcoming-slide-left {
    margin-left: 25% !important;
    height: 320px !important;
    width: 524px !important;
  }
  .far-slide-left {
    margin-left: 27% !important;
    height: 240px !important;
  }
}
