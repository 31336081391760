@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300..700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-weight: antialiased;
  }

  textarea {
    @apply box-content min-h-[96px] rounded border px-4 py-3 text-base font-medium;
    @apply resize-none;
    @apply border-input-stroke-unfilled bg-input-background-filled text-input-content-filled;
    @apply placeholder:border-input-stroke-unfilled placeholder:text-input-content-unfilled;
    @apply hover:border-input-stroke-hover hover:bg-input-background-hover hover:text-input-content-hover;
    @apply focus:border-input-stroke-focus focus:bg-input-background-focus focus:text-input-content-focus focus:outline-none focus:ring focus:ring-input-stroke-focus;
    @apply disabled:border-input-stroke-disabled disabled:bg-input-background-disabled disabled:text-input-content-disabled;
    @apply read-only:border-input-stroke-readonly read-only:bg-input-background-readonly read-only:text-input-content-readonly;
  }

  textarea.error {
    @apply border-input-stroke-error bg-input-background-error text-input-content-error;
  }

  .loading-button {
    @apply !cursor-default !border-button-stroke-secondary-pressed !bg-button-background-secondary-pressed !text-button-content-secondary-pressed;
  }

  input[type='password'].granite-input,
  input[type='text'].granite-input {
    @apply min-h-[48px] rounded border px-4 text-base font-medium;
    @apply border-input-stroke-unfilled bg-input-background-filled text-input-content-filled;
  }

  input[type='password'].granite-input-primary,
  input[type='text'].granite-input-primary {
    @apply placeholder:border-input-stroke-unfilled placeholder:text-input-content-unfilled;
    @apply hover:border-input-stroke-hover hover:bg-input-background-hover hover:text-input-content-hover;
    @apply focus:border-input-stroke-focus focus:bg-input-background-focus focus:text-input-content-focus focus:outline-none focus:ring-0;
    @apply disabled:border-input-stroke-disabled disabled:bg-input-background-disabled disabled:text-input-content-disabled;
    @apply read-only:border-input-stroke-readonly read-only:bg-input-background-readonly read-only:text-input-content-readonly;
  }

  input[type='password'].granite-input-outlined,
  input[type='text'].granite-input-outlined {
    @apply bg-background-base-surface-2;
    @apply placeholder:border-input-stroke-unfilled placeholder:bg-background-base-surface-2 placeholder:text-input-content-unfilled;
    @apply hover:border-input-stroke-hover hover:bg-background-base-surface-2 hover:text-input-content-hover;
    @apply focus:border-input-stroke-focus focus:bg-background-base-surface-2 focus:text-input-content-focus focus:outline-none focus:ring-0;
    @apply disabled:border-input-stroke-disabled disabled:bg-background-base-surface-2 disabled:text-input-content-disabled;
  }

  input[type='text'].granite-input-blue {
    @apply bg-background-base-surface-0;
    @apply placeholder:border-input-stroke-unfilled placeholder:text-input-content-unfilled;
    @apply hover:border-[#6C9DFF] hover:bg-background-base-surface-0;
    @apply focus:border-[#6C9DFF] focus:bg-background-base-surface-0 focus:outline-none focus:ring-0;
    @apply disabled:border-[#6C9DFF]/50 disabled:bg-background-base-surface-0 disabled:text-[#6C9DFF]/50;
    @apply read-only:border-[#6C9DFF]/50 read-only:bg-background-base-surface-0 read-only:text-[#6C9DFF]/50;
  }

  input[type='text'].granite-input-outlined.error {
    @apply !bg-background-base-surface-2;
  }

  input[type='text'].granite-input-blue.error {
    @apply !bg-background-base-surface-0;
  }

  input[type='text'].granite-input-outlined:-webkit-autofill,
  input[type='text'].granite-input-outlined:-webkit-autofill:hover,
  input[type='text'].granite-input-outlined:-webkit-autofill:focus,
  input[type='text'].granite-input-outlined:-webkit-autofill:active,
  input[type='password'].granite-input-outlined:-webkit-autofill,
  input[type='password'].granite-input-outlined:-webkit-autofill:hover,
  input[type='password'].granite-input-outlined:-webkit-autofill:focus,
  input[type='password'].granite-input-outlined:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(var(--neutral-100));
    -webkit-box-shadow: 0 0 0 30px rgba(var(--background-base-surface-2)) inset !important;
  }

  input[type='text'].granite-input-blue:-webkit-autofill,
  input[type='text'].granite-input-blue:-webkit-autofill:hover,
  input[type='text'].granite-input-blue:-webkit-autofill:focus,
  input[type='text'].granite-input-blue:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(var(--neutral-100));
    -webkit-box-shadow: 0 0 0 30px rgba(var(--background-base-surface-0)) inset !important;
  }

  input[type='text']:-webkit-autofill,
  input[type='text']:-webkit-autofill:hover,
  input[type='text']:-webkit-autofill:focus,
  input[type='text']:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(var(--neutral-100));
    -webkit-box-shadow: 0 0 0 30px rgba(var(--blue-900)) inset !important;
  }

  input[type='text'].granite-input.error {
    @apply border-input-stroke-error bg-input-background-error text-input-content-error;
  }

  .button {
    @apply box-content flex items-center justify-center gap-2 rounded px-6;
  }

  .button.large {
    @apply min-h-[48px] px-8 text-base font-bold;
  }

  .button.medium {
    @apply min-h-[32px] text-sm font-bold;
  }

  .button.small {
    @apply min-h-[24px] px-3 text-xs font-bold;
  }

  .button.primary {
    @apply bg-button-background-primary-default text-button-content-primary-default;
    @apply hover:bg-button-background-primary-hover hover:text-button-content-primary-hover;
    @apply ring-button-stroke-primary-focus ring-offset-2 ring-offset-background-base-surface-1 focus:bg-button-background-primary-focus focus:text-button-content-primary-focus focus:ring-2;
    @apply active:border-button-stroke-primary-pressed active:bg-button-background-primary-pressed active:text-button-content-primary-pressed;
    @apply disabled:bg-button-background-primary-disabled disabled:text-button-content-primary-disabled;
  }

  .button.secondary {
    @apply border border-button-stroke-secondary-default bg-button-background-secondary-default text-button-content-secondary-default;
    @apply hover:border-button-stroke-secondary-hover hover:bg-button-background-secondary-hover hover:text-button-content-secondary-hover;
    @apply ring-button-stroke-secondary-focus ring-offset-2 ring-offset-background-base-surface-1 focus:bg-button-background-secondary-focus focus:text-button-content-secondary-focus focus:ring-2;
    @apply active:border-button-stroke-secondary-pressed active:bg-button-background-secondary-pressed active:text-button-content-secondary-pressed;
    @apply disabled:border-button-stroke-secondary-disabled disabled:bg-button-background-secondary-disabled disabled:text-button-content-secondary-disabled;
  }

  .button.secondary svg {
    @apply fill-current text-current;
  }

  .filter-content-accent-default {
    @apply [filter:brightness(0)_saturate(100%)_invert(78%)_sepia(33%)_saturate(582%)_hue-rotate(154deg)_brightness(102%)_contrast(105%)];
  }

  .access-active-svg {
    animation: accessActiveAnimation 0.4s;
    transform: translateX(-21%);
  }

  .tech-active-svg {
    animation: techActiveAnimation 0.4s;
    transform: translateX(-35%);
  }

  .noc-active-svg {
    animation: nocActiveAnimation 0.4s;
    transform: translateX(-32%);
  }

  .access-desktop-svg {
    animation: accessAnimation 0.4s;
    transform: translateX(19%);
  }

  .tech-desktop-svg {
    animation: techAnimation 0.4s;
    transform: translateX(22%);
  }

  .noc-desktop-svg {
    animation: nocAnimation 0.4s;
    transform: translateX(23%);
  }

  .provide-feedback {
    position: relative;
    border: none;
    border-radius: 4px;
    background: rgb(130, 240, 255);
    background: linear-gradient(
      90deg,
      rgba(130, 240, 255, 0.16) 0%,
      rgba(151, 150, 243, 0.16) 50%,
      rgba(255, 154, 81, 0.16) 100%
    );
  }

  .pinned-summary path {
    fill: #9796f3;
  }

  .pinned-summary-radial-gradient {
    background: radial-gradient(
      150% 150% at 0% 50%,
      rgba(255, 255, 255, 0.06) 0%,
      rgba(255, 255, 255, 0.02) 100%
    );
  }

  .added-to-cart-service {
    background: linear-gradient(
        0deg,
        rgba(151, 150, 243, 0.06) 0%,
        rgba(151, 150, 243, 0.06) 100%
      ),
      #0d1219;
  }

  .provide-feedback::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 4px;
    padding: 1px;
    background: linear-gradient(to right, #82f0ff, #9796f3, #ff9a51);
    mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }

  .service-address-checkout {
    border-radius: 4px;
    border: 1px solid #7e77b3;
    background: linear-gradient(
      90deg,
      rgba(126, 119, 179, 0.08) 0.1%,
      rgba(137, 137, 255, 0.08) 25.07%,
      rgba(108, 157, 255, 0.08) 49.94%,
      rgba(120, 207, 218, 0.08) 75%,
      rgba(159, 244, 169, 0.08) 99.97%
    );
  }

  @media (max-width: 860px) {
    .table-column-mobile {
      border-top: 1px solid rgba(var(--stroke-base-default) / 0.5);
      border-bottom: 1px solid rgba(var(--stroke-base-default) / 0.5);
      margin: 0 -16px;
      width: calc(100% + 32px) !important;
    }
    .table-column-mobile > div[role='row'] > div:first-child,
    .table-column-mobile > div[role='columnheader']:first-child {
      padding-left: 16px;
      padding-right: 16px;
    }

    .sticky-first-column-mobile > div[role='row'] > div:first-child,
    .sticky-first-column-mobile > div[role='columnheader']:first-child {
      position: sticky;
      left: 0;
      z-index: 1;
      background-color: rgba(var(--background-base-surface-2));
      border-right: 1px solid rgba(var(--stroke-base-default));
    }
    .table-column-mobile > div[role='row'] {
      padding-top: 0;
      padding-bottom: 0;
    }
    .table-column-mobile > div[role='columnheader'] {
      margin-bottom: 0;
    }
    .table-column-mobile > div[role='columnheader'] + div[role='row'] {
      border-top: 1px solid rgba(var(--stroke-base-default) / 0.5);
    }
  }

  .shine-effect {
    position: relative;
    background-repeat: no-repeat;
    background-position:
      0 200%,
      0 0;
    background-image: linear-gradient(
      180deg,
      transparent 20%,
      transparent 40%,
      rgb(68, 68, 68, 0.2) 50%,
      rgb(68, 68, 68, 0.2) 55%,
      transparent 70%,
      transparent 100%
    );
    background-size:
      250% 250%,
      100% 100%;
    transition: background-position 0s ease;
  }

  .shine-effect.shine {
    background-position:
      0 -100%,
      0 0;
    transition-duration: 1.5s;
  }

  @keyframes accessAnimation {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(19%);
    }
  }

  @keyframes techAnimation {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(22%);
    }
  }

  @keyframes nocAnimation {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(23%);
    }
  }

  @keyframes nocActiveAnimation {
    from {
      transform: translateX(-15px);
    }

    to {
      transform: translateX(-32%);
    }
  }

  @keyframes techActiveAnimation {
    from {
      transform: translateX(-15px);
    }

    to {
      transform: translateX(-35%);
    }
  }

  @keyframes accessActiveAnimation {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(-21%);
    }
  }

  .button.ghost {
    @apply border border-transparent text-button-content-ghost-default;
    @apply hover:border-button-stroke-ghost-hover hover:text-button-content-ghost-hover;
    @apply ring-button-stroke-ghost-focus ring-offset-2 ring-offset-background-base-surface-1 focus:text-button-content-ghost-focus focus:ring-2;
    @apply active:text-button-content-ghost-pressed;
    @apply disabled:text-button-content-ghost-disabled;
  }

  .button.destructive {
    @apply bg-button-background-destructive-default text-button-content-destructive-default;
    @apply hover:bg-button-background-destructive-hover hover:text-button-content-destructive-hover;
    @apply ring-button-stroke-destructive-focus ring-offset-2 ring-offset-background-base-surface-1 focus:bg-button-background-destructive-focus focus:text-button-content-destructive-focus focus:ring-2;
    @apply active:bg-button-background-destructive-pressed active:text-button-content-destructive-pressed;
    @apply disabled:bg-button-background-destructive-disabled disabled:text-button-content-destructive-disabled;
  }

  .button.destructive-secondary {
    @apply border border-red-500 text-red-500;
    @apply hover:bg-red-500 hover:text-blue-900;
    @apply ring-button-stroke-destructive-focus ring-offset-2 ring-offset-background-base-surface-1 focus:bg-button-background-destructive-focus focus:text-button-content-destructive-focus focus:ring-2;
    @apply active:bg-button-background-destructive-pressed active:text-button-content-destructive-pressed;
    @apply disabled:border-button-stroke-secondary-disabled disabled:bg-button-background-secondary-disabled disabled:text-button-content-secondary-disabled;
  }

  .button.destructive-secondary svg {
    @apply fill-current text-current;
  }

  .button.tertiary {
    @apply bg-button-background-tertiary-default text-button-content-tertiary-default;
    @apply hover:bg-button-background-tertiary-hover hover:text-button-content-tertiary-default;
    @apply ring-button-stroke-tertiary-focus ring-offset-2 ring-offset-background-base-surface-1 focus:bg-button-background-tertiary-focus focus:text-button-content-tertiary-focus focus:ring-2;
    @apply active:bg-button-background-tertiary-pressed active:text-button-content-tertiary-pressed;
    @apply disabled:bg-button-background-tertiary-disabled disabled:text-button-content-tertiary-disabled;
  }

  .tab.underline-granite {
    @apply cursor-pointer border-b-2 px-4 text-base font-bold;
    @apply border-b-stroke-base-default text-content-base-default;
    @apply disabled:border-b-stroke-base-default disabled:text-content-base-subdued;
    @apply hover:border-b-button-background-primary-hover hover:text-button-background-primary-hover;
    @apply focus:border focus:border-button-stroke-primary-focus focus:text-content-base-default;
    @apply active:border-b-teal-700 active:text-teal-700;
  }

  .tab.underline-granite.active {
    @apply border-b-stroke-accent-default text-content-accent-default;
  }
  .tab.medium {
    @apply h-[32px];
  }

  .tab.large {
    @apply h-[40px];
  }

  .tab.pill-accent-primary {
    @apply cursor-pointer rounded-full px-4 text-base font-bold;
    @apply text-content-base-default;
    @apply disabled:text-content-base-subdued;
    @apply hover:bg-accent-primary hover:text-blue-900;
    @apply focus:ring-1 focus:ring-accent-primary;
    @apply active:bg-accent-primary active:text-blue-900;
  }

  .tab.pill-accent-primary.active {
    @apply bg-accent-primary text-blue-900;
  }

  .vertical-scrollbar {
    @apply overflow-y-scroll scrollbar-thin scrollbar-track-background-base-surface-1 scrollbar-thumb-stroke-base-subdued;
  }

  /* For Webkit-based browsers (Chrome, Safari and Opera) */
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }

  /* For IE, Edge and Firefox */
  .scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .disabled-link {
    pointer-events: none;
    @apply !bg-button-background-primary-disabled !text-button-content-primary-disabled;
  }

  .home-bg {
    background-image: url('./assets/images/vector.svg') !important;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: 92px;
  }

  :root {
    /* Base colors used by both light and dark themes */
    --black: 13 18 25;

    --blue-100: 236 242 255;
    --blue-200: 200 221 246;
    --blue-300: 150 186 255;
    --blue-400: 107 157 254;
    --blue-500: 61 121 239;
    --blue-600: 26 74 171;
    --blue-700: 43 43 63;
    --blue-800: 34 34 49;
    --blue-900: 25 25 37;

    --green-100: 242 255 244;
    --green-200: 205 255 211;
    --green-300: 167 255 178;
    --green-400: 130 255 145;
    --green-500: 89 196 102;
    --green-600: 71 168 83;
    --green-700: 55 138 65;
    --green-800: 40 109 49;
    --green-900: 27 80 34;

    --neutral-100: 248 250 252;
    --neutral-200: 241 245 249;
    --neutral-300: 226 232 240;
    --neutral-400: 203 213 225;
    --neutral-500: 148 163 184;
    --neutral-600: 100 116 139;
    --neutral-700: 71 85 105;
    --neutral-800: 51 65 85;
    --neutral-900: 30 41 59;

    --orange-100: 255 247 237;
    --orange-200: 254 237 214;
    --orange-300: 251 185 121;
    --orange-400: 249 146 71;
    --orange-500: 247 115 42;
    --orange-600: 232 89 33;
    --orange-700: 192 66 27;
    --orange-800: 152 53 26;
    --orange-900: 123 45 23;

    --pink-100: 253 242 248;
    --pink-200: 252 231 243;
    --pink-300: 247 169 211;
    --pink-400: 242 117 181;
    --pink-500: 234 76 153;
    --pink-600: 217 45 119;
    --pink-700: 188 30 94;
    --pink-800: 155 27 78;
    --pink-900: 130 27 67;

    --purple-100: 221 221 252;
    --purple-200: 187 186 250;
    --purple-300: 151 150 243;
    --purple-400: 123 123 233;
    --purple-500: 81 83 221;
    --purple-600: 59 62 190;
    --purple-700: 41 44 137;
    --purple-800: 29 31 96;
    --purple-900: 20 22 71;

    --red-100: 255 234 239;
    --red-200: 255 188 202;
    --red-300: 255 141 166;
    --red-400: 255 95 130;
    --red-500: 255 49 94;
    --red-600: 225 29 72;
    --red-700: 157 1 35;
    --red-800: 123 0 27;
    --red-900: 89 0 20;

    --teal-100: 242 253 255;
    --teal-200: 205 249 255;
    --teal-300: 167 244 255;
    --teal-400: 130 240 255;
    --teal-500: 89 183 196;
    --teal-600: 71 156 168;
    --teal-700: 55 128 138;
    --teal-800: 40 101 109;
    --teal-900: 27 74 80;

    --white: 248 250 252;

    --yellow-100: 254 252 233;
    --yellow-200: 254 248 198;
    --yellow-300: 252 223 86;
    --yellow-400: 249 203 52;
    --yellow-500: 233 178 43;
    --yellow-600: 201 137 32;
    --yellow-700: 160 98 24;
    --yellow-800: 132 77 24;
    --yellow-900: 112 63 24;

    /* Dark theme */
    /* These variable names are provided by figma, but we append a _ before the name. */
    --_background-base-overlay: #0d1219cc;
    --_background-base-surface-0: var(--black);
    --_background_base_surface-3_subdued: #2b2b3f80;
    --_background-base-surface-1: var(--blue-900);
    --_background-base-surface-2: var(--blue-800);
    --_background-base-surface-3: var(--blue-700);
    --_button-background-destructive-default: var(--_status-error-default);
    --_button-background-destructive-disabled: var(--neutral-500);
    --_button-background-destructive-focus: var(--_status-error-default);
    --_button-background-destructive-hover: var(--red-400);
    --_button-background-destructive-pressed: var(--_status-error-subdued);
    --_button-background-primary-default: var(--teal-400);
    --_button-background-primary-disabled: var(--neutral-500);
    --_button-background-primary-focus: var(--teal-400);
    --_button-background-primary-hover: var(--teal-300);
    --_button-background-primary-pressed: var(--teal-600);
    --_button-background-secondary-default: #ffffff00;
    --_button-background-secondary-disabled: #94a3b800;
    --_button-background-secondary-focus: #ffffff00;
    --_button-background-secondary-hover: var(--teal-400);
    --_button-background-secondary-pressed: var(--teal-600);
    --_button-content-destructive-default: var(--_content-flip-default);
    --_button-content-destructive-disabled: var(--_content-flip-subdued);
    --_button-content-destructive-focus: var(--_content-flip-default);
    --_button-content-destructive-hover: var(--_content-flip-default);
    --_button-content-destructive-pressed: var(--_content-base-default);
    --_button-content-ghost-default: var(--_content-base-default);
    --_button-content-ghost-disabled: var(--neutral-500);
    --_button-content-ghost-focus: var(--_content-base-default);
    --_button-content-ghost-hover: var(--_content-accent-default);
    --_button-content-ghost-pressed: var(--teal-700);
    --_button-content-primary-default: var(--_content-flip-default);
    --_button-content-primary-disabled: var(--_content-flip-subdued);
    --_button-content-primary-focus: var(--_content-flip-default);
    --_button-content-primary-hover: var(--_content-flip-default);
    --_button-content-primary-pressed: var(--_content-flip-default);
    --_button-content-secondary-default: var(--_content-base-default);
    --_button-content-secondary-disabled: var(--neutral-500);
    --_button-content-secondary-focus: var(--_content-base-default);
    --_button-content-secondary-hover: var(--_content-flip-default);
    --_button-content-secondary-pressed: var(--_content-flip-default);
    --_button-stroke-destructive-focus: var(--_stroke-accent-default);
    --_button-stroke-ghost-focus: var(--_stroke-accent-default);
    --_button-stroke-ghost-hover: var(--_stroke-accent-default);
    --_button-stroke-primary-focus: var(--_stroke-accent-default);
    --_button-stroke-secondary-default: var(--neutral-400);
    --_button-stroke-secondary-disabled: var(--neutral-500);
    --_button-stroke-secondary-focus: var(--_stroke-accent-default);
    --_button-stroke-secondary-hover: var(--teal-300);
    --_button-stroke-secondary-pressed: #82f0ff00;
    --_button-background-tertiary-default: #9796f3;
    --_button-background-tertiary-hover: #bbbafa;
    --_button-background-tertiary-focus: #9796f3;
    --_button-background-tertiary-pressed: #7b7be9;
    --_button-background-tertiary-disabled: #94a3b8;
    --_button-stroke-tertiary-focus: #7b7be9;
    --_button-content-tertiary-default: #191925;
    --_button-content-tertiary-hover: #191925;
    --_button-content-tertiary-focus: #191925;
    --_button-content-tertiary-pressed: #191925;
    --_button-content-tertiary-disabled: #475569;
    --_user-role-basic-user: #96baff;
    --_user-role-super-user: #3d79ef;
    --_user-role-admin: #1a4bab;
    --_button-stroke-primary-pressed: var(--teal-600);
    --_content-accent-alt: var(--blue-400);
    --_content-accent-default: var(--teal-400);
    --_content-base-default: var(--neutral-100);
    --_content-base-subdued: var(--neutral-500);
    --_content-flip-default: var(--blue-900);
    --_content-flip-subdued: var(--neutral-600);
    --_input-background-disabled: var(--neutral-900);
    --_input-background-error: var(--_background-base-surface-1);
    --_input-background-filled: var(--_background-base-surface-1);
    --_input-background-focus: var(--_background-base-surface-1);
    --_input-background-hover: var(--_background-base-surface-1);
    --_input-background-readonly: var(--neutral-900);
    --_input-background-selected: var(--teal-400);
    --_input-background-unfilled: var(--_background-base-surface-1);
    --_input-content-disabled: var(--_content-base-subdued);
    --_input-content-error: var(--_status-error-default);
    --_input-content-filled: var(--_content-base-default);
    --_input-content-focus: var(--_content-base-default);
    --_input-content-hover: var(--_content-base-default);
    --_input-content-readonly: var(--_content-base-subdued);
    --_input-content-selected: var(--_content-flip-default);
    --_input-content-unfilled: var(--_content-base-subdued);
    --_input-stroke-disabled: var(--_stroke-base-default);
    --_input-stroke-error: var(--_status-error-default);
    --_input-stroke-filled: var(--_stroke-base-heavy);
    --_input-stroke-focus: var(--_stroke-accent-default);
    --_input-stroke-hover: var(--_stroke-accent-default);
    --_input-stroke-readonly: var(--_stroke-base-default);
    --_input-stroke-selected: var(--_stroke-accent-default);
    --_input-stroke-unfilled: var(--_stroke-base-heavy);
    --_status-error-default: var(--red-500);
    --_status-error-subdued: var(--red-700);
    --_status-info-default: var(--purple-300);
    --_status-info-subdued: var(--blue-600);
    --_status-success-default: var(--green-400);
    --_status-success-subdued: var(--green-700);
    --_status-warning-default: var(--orange-400);
    --_status-warning-subdued: var(--orange-700);
    --_stroke-accent-alt: var(--blue-400);
    --_stroke-accent-default: var(--teal-400);
    --_stroke-base-default: var(--neutral-700);
    --_stroke-base-heavy: var(--neutral-500);
    --_stroke-base-subdued: #4755697f;
    --_ticket-priority-1: var(--blue-600);
    --_ticket-priority-2: var(--blue-500);
    --_ticket-priority-3: var(--blue-300);
    --_ticket-priority-premium: #2a2c89;
    --_ticket-status-cancelled: var(--red-400);
    --_ticket-status-complete: var(--teal-400);
    --_ticket-status-onhold: var(--yellow-300);
    --_ticket-status-pendingtechassignment: var(--orange-300);
    --_ticket-status-techassigned: var(--purple-300);
    --_ticket-status-techcheckedin: var(--green-400);
    --_ticket-status-pendingcustomer: var(--red-500);
    --_user-status-active: #82ff91;
    --_user-status-deactivated: #94a3b8;

    /*
         Tailwind references this variables
         We can swap implementations of the semantic variables between the light and dark themes.
         */

    --background-base-overlay: var(--_background-base-overlay);
    --background-base-surface-0: var(--_background-base-surface-0);
    --background_base_surface-3_subdued: var(
      --_background_base_surface-3_subdued
    );
    --background-base-surface-1: var(--_background-base-surface-1);
    --background-base-surface-2: var(--_background-base-surface-2);
    --background-base-surface-3: var(--_background-base-surface-3);
    --button-background-destructive-default: var(
      --_button-background-destructive-default
    );
    --button-background-destructive-disabled: var(
      --_button-background-destructive-disabled
    );
    --button-background-destructive-focus: var(
      --_button-background-destructive-focus
    );
    --button-background-destructive-hover: var(
      --_button-background-destructive-hover
    );
    --button-background-destructive-pressed: var(
      --_button-background-destructive-pressed
    );
    --button-background-primary-default: var(
      --_button-background-primary-default
    );
    --button-background-primary-disabled: var(
      --_button-background-primary-disabled
    );
    --button-background-primary-focus: var(--_button-background-primary-focus);
    --button-background-primary-hover: var(--_button-background-primary-hover);
    --button-background-primary-pressed: var(
      --_button-background-primary-pressed
    );
    --button-background-secondary-default: var(
      --_button-background-secondary-default
    );
    --button-background-secondary-disabled: var(
      --_button-background-secondary-disabled
    );
    --button-background-secondary-focus: var(
      --_button-background-secondary-focus
    );
    --button-background-secondary-hover: var(
      --_button-background-secondary-hover
    );
    --button-background-secondary-pressed: var(
      --_button-background-secondary-pressed
    );
    --button-content-destructive-default: var(
      --_button-content-destructive-default
    );
    --button-content-destructive-disabled: var(
      --_button-content-destructive-disabled
    );
    --button-content-destructive-focus: var(
      --_button-content-destructive-focus
    );
    --button-content-destructive-hover: var(
      --_button-content-destructive-hover
    );
    --button-content-destructive-pressed: var(
      --_button-content-destructive-pressed
    );
    --button-content-ghost-default: var(--_button-content-ghost-default);
    --button-content-ghost-disabled: var(--_button-content-ghost-disabled);
    --button-content-ghost-focus: var(--_button-content-ghost-focus);
    --button-content-ghost-hover: var(--_button-content-ghost-hover);
    --button-content-ghost-pressed: var(--_button-content-ghost-pressed);
    --button-content-primary-default: var(--_button-content-primary-default);
    --button-content-primary-disabled: var(--_button-content-primary-disabled);
    --button-content-primary-focus: var(--_button-content-primary-focus);
    --button-content-primary-hover: var(--_button-content-primary-hover);
    --button-content-primary-pressed: var(--_button-content-primary-pressed);
    --button-content-secondary-default: var(
      --_button-content-secondary-default
    );
    --button-content-secondary-disabled: var(
      --_button-content-secondary-disabled
    );
    --button-content-secondary-focus: var(--_button-content-secondary-focus);
    --button-content-secondary-hover: var(--_button-content-secondary-hover);
    --button-content-secondary-pressed: var(
      --_button-content-secondary-pressed
    );
    --button-stroke-destructive-focus: var(--_button-stroke-destructive-focus);
    --button-stroke-ghost-focus: var(--_button-stroke-ghost-focus);
    --button-stroke-ghost-hover: var(--_button-stroke-ghost-hover);
    --button-stroke-primary-focus: var(--_button-stroke-primary-focus);
    --button-stroke-primary-pressed: var(--_button-stroke-primary-pressed);
    --button-stroke-secondary-default: var(--_button-stroke-secondary-default);
    --button-stroke-secondary-disabled: var(
      --_button-stroke-secondary-disabled
    );
    --button-stroke-secondary-focus: var(--_button-stroke-secondary-focus);
    --button-stroke-secondary-hover: var(--_button-stroke-secondary-hover);
    --button-stroke-secondary-pressed: var(--_button-stroke-secondary-pressed);
    --button-background-tertiary-default: var(
      --_button-background-tertiary-default
    );
    --button-background-tertiary-hover: var(
      --_button-background-tertiary-hover
    );
    --button-background-tertiary-focus: var(
      --_button-background-tertiary-focus
    );
    --button-background-tertiary-pressed: var(
      --_button-background-tertiary-pressed
    );
    --button-background-tertiary-disabled: var(
      --_button-background-tertiary-disabled
    );
    --button-stroke-tertiary-focus: var(--_button-stroke-tertiary-focus);
    --button-content-tertiary-default: var(--_button-content-tertiary-default);
    --button-content-tertiary-hover: var(--_button-content-tertiary-hover);
    --button-content-tertiary-focus: var(--_button-content-tertiary-focus);
    --button-content-tertiary-pressed: var(--_button-content-tertiary-pressed);
    --button-content-tertiary-disabled: var(
      --_button-content-tertiary-disabled
    );
    --user-role-basic-user: var(--_user-role-basic-user);
    --user-role-super-user: var(--_user-role-super-user);
    --user-role-admin: var(--_user-role-admin);
    --content-accent-alt: var(--_content-accent-alt);
    --content-accent-default: var(--_content-accent-default);
    --content-base-default: var(--_content-base-default);
    --content-base-subdued: var(--_content-base-subdued);
    --content-flip-default: var(--_content-flip-default);
    --content-flip-subdued: var(--_content-flip-subdued);
    --input-background-disabled: var(--_input-background-disabled);
    --input-background-error: var(--_input-background-error);
    --input-background-filled: var(--_input-background-filled);
    --input-background-focus: var(--_input-background-focus);
    --input-background-hover: var(--_input-background-hover);
    --input-background-readonly: var(--_input-background-readonly);
    --input-background-selected: var(--_input-background-selected);
    --input-background-unfilled: var(--_input-background-unfilled);
    --input-content-disabled: var(--_input-content-disabled);
    --input-content-error: var(--_input-content-error);
    --input-content-filled: var(--_input-content-filled);
    --input-content-focus: var(--_input-content-focus);
    --input-content-hover: var(--_input-content-hover);
    --input-content-readonly: var(--_input-content-readonly);
    --input-content-selected: var(--_input-content-selected);
    --input-content-unfilled: var(--_input-content-unfilled);
    --input-stroke-disabled: var(--_input-stroke-disabled);
    --input-stroke-error: var(--_input-stroke-error);
    --input-stroke-filled: var(--_input-stroke-filled);
    --input-stroke-focus: var(--_input-stroke-focus);
    --input-stroke-hover: var(--_input-stroke-hover);
    --input-stroke-readonly: var(--_input-stroke-readonly);
    --input-stroke-selected: var(--_input-stroke-selected);
    --input-stroke-unfilled: var(--_input-stroke-unfilled);
    --status-error-default: var(--_status-error-default);
    --status-error-subdued: var(--_status-error-subdued);
    --status-info-default: var(--_status-info-default);
    --status-info-subdued: var(--_status-info-subdued);
    --status-success-default: var(--_status-success-default);
    --status-success-subdued: var(--_status-success-subdued);
    --status-warning-default: var(--_status-warning-default);
    --status-warning-subdued: var(--_status-warning-subdued);
    --stroke-accent-alt: var(--_stroke-accent-alt);
    --stroke-accent-default: var(--_stroke-accent-default);
    --stroke-base-default: var(--_stroke-base-default);
    --stroke-base-heavy: var(--_stroke-base-heavy);
    --stroke-base-subdued: var(--_stroke-base-subdued);
    --ticket-priority-1: var(--_ticket-priority-1);
    --ticket-priority-2: var(--_ticket-priority-2);
    --ticket-priority-3: var(--_ticket-priority-3);
    --ticket-priority-premium: var(--_ticket-priority-premium);
    --ticket-status-cancelled: var(--_ticket-status-cancelled);
    --ticket-status-complete: var(--_ticket-status-complete);
    --ticket-status-onhold: var(--_ticket-status-onhold);
    --ticket-status-pendingtechassignment: var(
      --_ticket-status-pendingtechassignment
    );
    --ticket-status-techassigned: var(--_ticket-status-techassigned);
    --ticket-status-techcheckedin: var(--_ticket-status-techcheckedin);
    --ticket-status-pendingcustomer: var(--_ticket-status-pendingcustomer);
    --user-status-active: var(--_user-status-active);
    --user-status-deactivated: var(--_user-status-deactivated);

    --toastify-toast-width: fit-content !important;
    --toastify-font-family: Poppins, sans-serif !important;
  }
}

.Toastify__toast-container--top-right {
  top: 5.75rem !important;
}
