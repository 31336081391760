.search-box-wrapper {
  @apply flex min-h-[48px] items-center gap-2 rounded-t border fill-content-base-default px-4 text-base font-normal text-content-base-default last:rounded-b;
  @apply border-input-stroke-unfilled bg-input-background-unfilled;
  @apply focus-within:border-input-stroke-focus focus-within:bg-input-background-focus;
}

.search-box-wrapper > input {
  all: unset;
  @apply w-full;
}

.search-box-wrapper.error {
  @apply border-input-stroke-error;
}

.search-result-container {
  @apply z-50 max-h-[224px] overflow-y-scroll;
  @apply rounded-b border-b border-l border-r border-input-stroke-unfilled;
  @apply group-focus-within:border-input-stroke-focus;
  @apply scrollbar-thin scrollbar-track-input-background-unfilled scrollbar-thumb-background-base-surface-2;
}

.search-result-row {
  @apply flex flex-col items-start justify-between px-4 py-3;
  @apply bg-input-background-unfilled text-content-base-default hover:bg-background-base-surface-2;
  @apply cursor-pointer;
  @apply last:rounded-b;
  @apply rounded;
  @apply outline-none focus-visible:border focus-visible:border-input-stroke-focus focus-visible:bg-background-base-surface-2;
}
