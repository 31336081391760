.bg-gradient-radial {
  background: -webkit-radial-gradient(
    50% 0%,
    50% 50%,
    #0d1219 0%,
    #222231 100%
  );

  background: radial-gradient(
    125.68% 106.19% at 50% 26.98%,
    #0d1219 0%,
    #222231 100%
  );
}

.shadow-lg-custom {
  box-shadow:
    0px 4px 4px 0px #0000004d,
    0px 8px 12px 6px #00000026;
}

.bg-surface-2-subdued {
  background: var(--background-base-surface-2-subdued, #222231b2);
}

.access-express-svg {
  background-image: url('assets/images/accessexpress_vector.svg') !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: -45px;
}

.text-container {
  position: relative;
  height: 72px;
  overflow: hidden;
}

.scrolling-text {
  position: absolute;
  width: 100%;
  top: 0;
  opacity: 0;
  transform: translateY(100%);
  transition:
    transform 0.5s ease,
    opacity 0.5s ease;
}

.scrolling-text.active {
  opacity: 1;
  transform: translateY(0);
}

.scrolling-text.previous {
  opacity: 0;
  transform: translateY(-100%);
}

.svg-container {
  position: relative;
  width: 29px;
  height: 37px;
}
.svg-icon {
  position: absolute;
  top: 0;
  left: 0;
  animation: starMorph 2s forwards;
}

.star-svg path {
  fill: #2b2b3f;
}

.star-svg path {
  animation: starCycle 4s ease-out 0.8s infinite;
  fill: #2b2b3f;
}

@keyframes starCycle {
  0% {
    transform: scale(1);
    fill: #2b2b3f;
  }
  50% {
    transform: scale(1.26);
    fill: rgba(108, 157, 255, 0.15);
    box-shadow: 0 1px 3px 1 rgba(0, 0, 0, 0.15);
  }
  100% {
    transform: scale(1);
    fill: #2b2b3f;
  }
}

@property --myColor1 {
  syntax: '<color>';
  initial-value: #222231;
  inherits: false;
}

@property --myColor2 {
  syntax: '<color>';
  initial-value: #222231;
  inherits: false;
}

.card-gradient {
  background: linear-gradient(to top right, var(--myColor1), var(--myColor2));
  transition:
    --myColor1 0.5s,
    --myColor2 0.5s;
}

.card-gradient:hover {
  --myColor1: #37374d; /* Updated hover color */
  --myColor2: #222231; /* Updated hover color */
}

.card-gradient:hover {
  --myColor1: #37374d;
  --myColor2: #222231;
}

@property --gradientColor1 {
  syntax: '<color>';
  initial-value: #82f0ff;
  inherits: false;
}

@property --gradientColor2 {
  syntax: '<color>';
  initial-value: #9796f3;
  inherits: false;
}

@property --gradientColor3 {
  syntax: '<color>';
  initial-value: #ff9a51;
  inherits: false;
}

.hero-section-gradient-text {
  background: #fff;
  -webkit-background-clip: text;
  background-clip: text;
  background: linear-gradient(90deg, #82f0ff 0%, #9796f3 45%, #ff9a51 100%);
  -webkit-background-clip: text;
  background-clip: text;
}

.your-journey {
  font-size: 44px;
  line-height: 56px;
  position: relative;
  color: transparent;
  background: #fff;
  -webkit-background-clip: text;
  background-clip: text;
  transition:
    background 600ms linear,
    opacity 600ms linear;
  opacity: 1; /* Initial opacity is 1 */
}

.your-journey::after {
  content: 'Your journey to connectivity starts here';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  opacity: 1;
  transition: opacity 600ms linear;
}

.your-journey-hovered::after {
  opacity: 0;
}

.your-journey-hovered {
  background: linear-gradient(90deg, #82f0ff 0%, #9796f3 52.5%, #ff9a51 100%);
  -webkit-background-clip: text;
  background-clip: text;
}

.opacity-for-vectors > g:first-child {
  opacity: 0.7;
}
.opacity-right-circle > g:first-child {
  opacity: 0.38;
}

@-moz-document url-prefix() {
  .opacity-for-vectors > g:first-child {
    opacity: 0.88;
  }
  .opacity-right-circle > g:first-child {
    opacity: 0.65;
  }
}
