.DatePicker {
  @apply overflow-hidden;
  @apply flex min-h-[48px] rounded border text-base font-normal;
  @apply border-input-stroke-unfilled;
  @apply placeholder:border-input-stroke-unfilled;
  @apply hover:border-input-stroke-hover;
  @apply focus-within:border-input-stroke-focus;
  @apply disabled:border-input-stroke-disabled;
}

.DatePicker > input {
  all: unset;
  @apply w-full rounded bg-input-background-filled pl-4 tracking-wide text-input-content-filled;
  @apply placeholder:bg-input-background-unfilled placeholder:text-input-content-unfilled;
  @apply hover:bg-input-background-hover hover:text-input-content-hover;
  @apply focus:bg-input-background-focus focus:text-input-content-focus focus:outline-none focus:ring-0;
  @apply disabled:bg-input-background-disabled disabled:text-input-content-disabled;
  @apply read-only:bg-input-background-readonly read-only:text-input-content-readonly;
}

.DatePicker > button {
  @apply bg-input-background-filled px-4;
  @apply disabled:bg-input-background-disabled;
}

.DatePicker.error {
  @apply border-input-stroke-error;
}

.DatePicker.error > input {
  @apply bg-input-background-error text-input-content-error;
}
